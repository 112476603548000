export enum MagicPrefix {
  Anonymous = "storied-anon",
}

export enum SttProvider {
  DeepGram_Realtime = "deepgram-realtime",
  AssemblyAI_Realtime = "assembly-ai-realtime",
  DeepGram_PreRecorded = "deepgram-prerecorded",
  Whisper_PreRecorded = "whisper-prerecorded",
}

export enum SocketNamespace {
  OutputStream = "/socket/output",
  SpeechToText = "/socket/stt",
}

export enum UserRole {
  Admin = "admin",
  Regular = "regular",
}

export enum ImageType {
  TopicCover = "topic-cover",
  TopicEditorTools = "topic-editor-tools",
  SharedOutputCover = "shared-output-cover",
}

export enum UploadImageOperationType {
  UploadImageOperationType_File = "operation-type-file",
  UploadImageOperationType_Link = "operation-type-link",
}

export enum TTSType {
  Output = "output",
  SharedOutput = "shared-output",
  LiveQuestion = "live-question",
}

export enum TTSProvider {
  OpenAI = "openai",
  ElevenLabs = "eleven-labs",
}

export enum CommonUnixTime {
  OneMillisecond = 1,
  OneSecond = OneMillisecond * 1000,
  OneMinute = OneSecond * 60,
  OneHour = OneMinute * 60,
  OneDay = OneHour * 24,
  OneYear = OneDay * 365,
  Life = OneYear * 42,
}

export enum GptModelType {
  GPT35_TURBO = "gpt-3.5-turbo",
  GPT4 = "gpt-4",
  GPT4_TURBO = "gpt-4-turbo",
  GPT4_O = "gpt-4o",
  GPT4_O_MINI = "gpt-4o-mini",
}

export enum RecordingObjectType {
  IDEA = "idea",
  TOPIC = "topic",
  QUESTION = "question",
  ACTION_QUESTION = "action-question",
  PERSPECTIVE_QUESTION = "perspective-question",
  POP_QUESTION = "pop-question",
  MAIN_MESSAGE = "main-message",
  WRITING_STYLE = "writing-style",
  LET_ME_EXPLAIN = "let-me-explain",
  IMAGE_DESCRIPTION = "image-description",
  MAGIC_OUTLINE = "magic-outline",
  SHARED_OUTPUT_REVIEW = "shared-output-review",
}

export enum IdeaType {
  DEFAULT = "default",
  INITIAL_RECORDING = "initial-recording",
  QUESTION_ANSWER = "question-answer",
  MAIN_AI = "main-ai",
  MAIN_MANUAL = "main-manual",
  LIVE = "live",
}

export enum OutputState {
  PROCESSING = "processing",
  FINISHED = "finished",
  FAILED = "failed",
}

export enum ApiKeyType {
  DEEPGRAM_API_KEY = "deepgram-api-key",
}

export enum CustomStartType {
  TAKE_A_NOTE = "note",
  MEETING_PREP = "meeting-prep",
  HOLIDAY_NOTE = "holiday-note",
  NEW_YEARS_RESOLUTIONS = "new-years-resolutions",
  PRODUCT_HEROS_JOURNEY = "product-heros-journey",
  END_OF_DAY = "end-of-day",
  MEETING_RECAP = "meeting-recap",
  EMAIL = "email",
  LINKEDIN_POST = "linkedin-post",
  _NICK_HACK = "nick-hack",
}

export enum OutputGenerateReason {
  CLEANUP_TRANSCRIPT = "cleanup-transcript",
  DEFAULT = "default",
  CUSTOM_START = "custom-start",
  LET_ME_EXPLAIN = "let-me-explain",
  MAGIC_WAND = "magic-wand",
  PURPOSE = "purpose",
  QUESTIONS = "questions",
  WRITING_STYLE = "writing-style",
}

export enum QuestionType {
  ACTION = "action",
  PERSPECTIVE = "perspective",
  POP = "pop",
}

export enum ErrorCodes {
  UNAUTHORIZED = 403,
  INVALID_OPEN_AI_API_KEY = 521,
  INVALID_PROMPT_LAYER_API_KEY = 522,
  WHISPER_TRANSCRIBE_ERROR = 523,
  INVALID_CLIENT_EMAIL_FORMAT = 524,
  AUDIO_UPLOAD_FAILED = 525,
  INVALID_ACCESS_TOKEN = 526,
  HOST_BLOCKED_BY_CORS = 527,
  OPEN_AI_ERROR = 528,
  PROMPT_LAYER_PROMPT_ERROR = 530,
  DEEPGRAM_TRANSCRIBE_ERROR = 532,
  RESOURCE_MISSING = 533,
  INVALID_USER = 534,
  INVALID_IMAGE_FILE_TYPE = 535,
  INVALID_PARAMS = 536,
  UNDEFINED = 599,
}

export enum OutlineType {
  OUTLINE_TYPE_TEXT = "outline-type-text",
  OUTLINE_TYPE_FILE_AUDIO_UPLOADED = "outline-type-file-audio-uploaded",
  OUTLINE_TYPE_FILE_AUDIO_RECORDED = "outline-type-file-audio-recorded",
  OUTLINE_TYPE_FILE_TEXT = "outline-type-file-text",
  OUTLINE_TYPE_FILE_PDF = "outline-type-file-pdf",
  OUTLINE_TYPE_FILE_IMAGE = "outline-type-file-image",
}

export enum OnboardingFlowType {
  ONBOARDING_FLOW_RECORD = "OnboardingFlow_Record",
}

export enum PaymentTier {
  PAYMENT_TIER_EXPIRED = "payment-tier-expired",
  PAYMENT_TIER_BASIC = "payment-tier-basic",
  PAYMENT_TIER_PRO = "payment-tier-pro",
  PAYMENT_TIER_PRO_TRIAL = "payment-tier-pro-trial",
  PAYMENT_TIER_TEAM = "payment-tier-team",
}

export enum BillingCycle {
  BILLING_CICLE_MONTHLY = "billing-cycle-monthly",
  BILLING_CICLE_ANUALLY = "billing-cycle-anually",
}

export const ERROR_MESSAGES = {
  [ErrorCodes.INVALID_OPEN_AI_API_KEY]: "Invalid OpenAI API Key",
  [ErrorCodes.INVALID_PROMPT_LAYER_API_KEY]: "Invalid Prompt Layer API Key",
  [ErrorCodes.WHISPER_TRANSCRIBE_ERROR]: "Whisper Transcribe Error",
  [ErrorCodes.INVALID_CLIENT_EMAIL_FORMAT]: "Invalid Client Email Format",
  [ErrorCodes.AUDIO_UPLOAD_FAILED]: "Audio Upload Failed",
  [ErrorCodes.INVALID_ACCESS_TOKEN]: "Invalid Access Token",
  [ErrorCodes.HOST_BLOCKED_BY_CORS]: "CORS Error",
  [ErrorCodes.OPEN_AI_ERROR]: "OpenAI Error",
  [ErrorCodes.PROMPT_LAYER_PROMPT_ERROR]: "Prompt Layer Prompt Error",
  [ErrorCodes.DEEPGRAM_TRANSCRIBE_ERROR]: "DeepGram Transcribe Error",
  [ErrorCodes.UNAUTHORIZED]: "Unauthorized",
  [ErrorCodes.RESOURCE_MISSING]: "Resource Missing",
  [ErrorCodes.INVALID_USER]: "Invalid User",
  [ErrorCodes.INVALID_IMAGE_FILE_TYPE]: "Invalid Image File Type",
  [ErrorCodes.INVALID_PARAMS]: "Invalid Params",
  [ErrorCodes.UNDEFINED]: "Undefined error",
};

export const LANGUAGE_CODE_TO_LANGUAGE_MAP = {
  "en-US": "English",
  "en-GB": "English",
  es: "Spanish",
  pt: "Portuguesse",
  uk: "Ukrainian",
  zh: "Chinese",
  nl: "Dutch",
  fr: "French",
  de: "German",
  hi: "Hindi",
  id: "Indonesian",
  it: "Italian",
  ja: "Japanese",
  ko: "Korean",
  ru: "Russian",
  sv: "Swedish",
  tr: "Turkish",
};

export const StoriedAccounts = [
  "frank@storiedwork.com",
  "mircea@bestoried.ai",
  "jonmacrae14@gmail.com",
];
